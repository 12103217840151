<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef">
        <a-form-model-item 
            prop="financial_account_id" label="财务账户">
          <a-select style="width:320px;" placeholder="请选择账户"
               v-model="financial_account_id"
               @change="changeAccount">
            <a-select-option v-for="(item, index) in manageFinanceList"
                 :value="item.id"
                 :key="index">{{item.financial_account_name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="search_date" label="选择时间">
          <a-range-picker
            style="width:300px"
            v-model="search_date"
            valueFormat="YYYY-MM-DD"
            @change="onChangeDate"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

		<!-- 预订单的分布 -->
		<div class="flex justify-between mt-2">
			<div class="flex items-center">
				<h4 class="text-lg font-bold">钱包备案总览</h4>
			</div>
			<!-- <a-button @click="handlerDownload">下载本页</a-button> -->
		</div>

		<template v-if="tableData.length">
			<!-- 充值 -->
			<base-table style="max-width:800px;"
				:columnsData="columns_recharge"
				:tableData="tableData"
			></base-table>

			<!-- 消费 -->
			<base-table  style="max-width:800px;"
				:columnsData="columns_consume"
				:tableData="tableData"
			></base-table>
				
			<a-divider />

			<div class="flex">
				<base-table style="max-width:400px;"
					:columnsData="columns_inventory"
					:tableData="tableData"
				></base-table>
				<a-button class="p-0 ml-6" type="link" @click="handlerExport">导出备案文件</a-button>
			</div>
		</template>
			

  </div>
</template>

<script>
import { 
  isFinanceManage, 
  getFinanceList
} from "@/utils/authData.js"
import { 
	financeAccountVipcardCorp,
	financeAccountExportVipCardCorp
} from "@/api/finance/finance.js"

import { formatEnMoney } from "@/utils/index.js"

export default {
  components: {  },
  data() {
    return {
			formatEnMoney,

			isFinanceManage: isFinanceManage(),
      manageFinanceList: [],

			search_date: [],

			financial_account_id: undefined,

			tableData: [],

			// tableData_recharge: [],
			columns_recharge: [
				{
					title: "充值次数",
					dataIndex: "vip_card_recharge_number",
					align: "center",
					width: 100,
				},
				{
					title: "充值本金(元)",
					dataIndex: "vip_card_recharge_amount",
					align: "center",
					// width: 160,
					slots: {
						customRender: "vip_card_recharge_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "充值金额(元)",
					dataIndex: "vip_card_recharge_amount_m",
					align: "center",
					// width: 160,
					slots: {
						customRender: "vip_card_recharge_amount_m",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "退余额次数 *以客服审核为准",
					dataIndex: "vip_card_refund_number",
					align: "center",
				},
				{
					title: "退余额本金(元) *以客服审核为准",
					dataIndex: "vip_card_refund_amount",
					align: "center",
					slots: {
						customRender: "vip_card_refund_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "退余额(元) *以客服审核为准",
					dataIndex: "vip_card_refund_amount_m",
					align: "center",
					slots: {
						customRender: "vip_card_refund_amount_m",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			// tableData_consume: [],
			columns_consume: [
				{
					title: "消费次数",
					dataIndex: "consumption_number",
					align: "center",
					width: 100,
				},
				{
					title: "消费金额本金(元)",
					dataIndex: "consumption_amount",
					align: "center",
					// width: 160,
					slots: {
						customRender: "consumption_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "消费金额(元)",
					dataIndex: "consumption_amount_m",
					align: "center",
					// width: 160,
					slots: {
						customRender: "consumption_amount_m",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "消费退款次数",
					dataIndex: "consumption_refund_number",
					align: "center",
				},
				{
					title: "消费退款本金(元)",
					dataIndex: "consumption_refund_amount",
					align: "center",
					slots: {
						customRender: "consumption_refund_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "消费退款金额(元)",
					dataIndex: "consumption_refund_amount_m",
					align: "center",
					slots: {
						customRender: "consumption_refund_amount_m",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			// tableData_inventory: [],
			columns_inventory: [
				{
					title: "盘存钱包数",
					dataIndex: "time_end_rich_vip_card_number",
					align: "center",
					width: 100,
				},
				{
					title: "盘存总本金(元)",
					dataIndex: "time_end_rich_vip_card_amount",
					align: "center",
					// width: 160,
					slots: {
						customRender: "time_end_rich_vip_card_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "盘存总余额(元)",
					dataIndex: "time_end_rich_vip_card_amount_m",
					align: "center",
					// width: 160,
					slots: {
						customRender: "time_end_rich_vip_card_amount_m",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],
    }
  },

  async mounted() {
  	this.manageFinanceList = await getFinanceList(true)
  },
  methods: {
    changeAccount(){
    	if (this.search_date.length < 2) {
				return
			}
			this.handlerSearch()
    },

		onChangeDate(){
			if (this.search_date.length < 2) {
				return
			}
			this.handlerSearch()
		},

    handlerDownload(){
      const XLSX = require('xlsx')

      let wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, XLSX.utils.table_to_sheet(document.querySelector("#settle-table")), "内部结算(单位元)")

      XLSX.writeFile(wb, "财务账户-内部结算.xlsx")
    },

    async handlerSearch() {
    	if(this.search_date.length < 2){
				this.$message.warning("请选择时间")
				return
			}
			if(new Date(this.search_date[1]).getTime() - new Date(this.search_date[0]).getTime() > 93 * 24 * 3600 * 1000){
				this.$message.warning("时间跨度不能超三个月")
				return
			}

			const params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + ' 00:00:00',
				time_end:   this.search_date[1] + ' 23:59:59'
			}
			const { data, code } = await financeAccountVipcardCorp(params)
			if (code === 0) {
				this.tableData = [data]
				// this.tableData_recharge = [data]
				// this.tableData_consume = [data]
				// this.tableData_inventory = [data]
			}
    },

		handlerExport(){
			if(this.search_date.length < 2){
				this.$message.warning("请选择时间")
				return
			}

			let params = {
				financial_account_id: this.financial_account_id,
				time_end: this.search_date[1] + ' 23:59:59'
			}
			
			financeAccountExportVipCardCorp(params)
		},

  },
}
</script>

<style lang="less">

</style>